import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { RadioCo } from "@src/services/radioco";

export function useStreamInfoQuery(url: string) {
  const enabled = useMemo(() => {
    if (url) {
      console.log("useStreamInfoQuery enabled");
      return true;
    } else {
      console.log("useStreamInfoQuery disabled");
      return false;
    }
  }, [url]);

  return useQuery({
    enabled,
    refetchInterval: 60_000, // refetch every minute
    queryKey: ["stream_info", url],
    queryFn: async () => {
      const streamInfo = await RadioCo.getStreamInfo(url);
      return streamInfo;
    },
  });
}
