import { CanopyPlayer } from "@src/pages/CanopyPlayer";
import { LitePlayer } from "@src/pages/LitePlayer";
import { Player } from "@src/pages/Player";

import { useRadioSession } from "@src/hooks/useRadioSession";

export function PlayerRenderer() {
  useRadioSession();

  const liteMode = window.location.search.includes("lite=1");
  if (liteMode) {
    return <LitePlayer />;
  }

  const canopyMode =
    window.location.pathname.includes("canopybyhilton") &&
    window.location.search.includes("popup=1");
  if (canopyMode) {
    return <CanopyPlayer />;
  }

  const raspberryMode = window.location.search.includes("lite=2");
  if (raspberryMode) {
    return <Player raspberry={true} />;
  }

  return <Player />;
}
