import { devMode } from "@src/env";
import { useEffect } from "react";

import { Artwork } from "@src/components/Artwork";
import { Background } from "@src/components/Background";
import { Debug } from "@src/components/Debug";
import { ErrorMessage } from "@src/components/ErrorMessage";
import { Footer } from "@src/components/Footer";
import { LoginInfo } from "@src/components/LoginInfo";
import { NextTrack } from "@src/components/NextTrack";
import { OfflineModeIndicator } from "@src/components/OfflineModeIndicator";
import { PlayPause } from "@src/components/PlayPause";
import { PreviousTracks } from "@src/components/PreviousTracks";
import { ServiceWorker } from "@src/components/ServiceWorker";
import { SocialMedia } from "@src/components/SocialMedia";
import { StreamInfo } from "@src/components/StreamInfo";
import { Version } from "@src/components/Version";

import { usePlayer } from "@src/hooks/usePlayer";

import "./style.less";
import type { PlayerProps } from "./types";

export function Player({ raspberry = false }: PlayerProps) {
  const { radio, logout, currentTrack, isOnlinePlaying } = usePlayer(raspberry);

  useEffect(() => {
    if (!raspberry) return;
    function onKeyDown(event: KeyboardEvent) {
      if (event.code === "Numpad5") {
        console.log("[onKeyDown] Refresh page");
        window.location.reload();
      } else if (event.code === "NumpadEnter") {
        console.log("[onKeyDown] Play/Pause");
        document.dispatchEvent(new Event("playPause"));
      }
    }
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [raspberry]);

  if (!radio) {
    return <></>;
  }

  return (
    <div className="Player-page">
      <ServiceWorker radio={radio} />
      <Background url={currentTrack.artwork_large} raspberry={raspberry} />
      <main>
        <OfflineModeIndicator radio={radio} />
        <Version idRadio={radio.folder} />
        <LoginInfo logged_in_as={radio.radio_name} logout={logout} />
        {raspberry ? (
          <>
            <PlayPause />
            <ErrorMessage />
          </>
        ) : (
          <>
            <SocialMedia />
            <PreviousTracks />
            <Artwork currentTrack={currentTrack} />
            <NextTrack />
            {isOnlinePlaying && <StreamInfo radio={radio} />}
          </>
        )}
        {devMode && <Debug radio={radio} />}
      </main>
      <Footer radio={radio} raspberry={raspberry} />
    </div>
  );
}
