import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { useCallback, useEffect, useRef } from "react";
import { IconContext } from "react-icons";
import { PiPauseCircleFill, PiPlayCircleFill } from "react-icons/pi";
import { Oval } from "react-loader-spinner";
import { useShallow } from "zustand/shallow";

import { useListener } from "@src/hooks/useListener";
import { useMount } from "@src/hooks/useMount";

import { usePlayerState } from "@src/states/player";

import { player } from "@src/services/player";
import { DEFAULT_TRACK_ID } from "@src/services/player/constants";

import { setupOnlineMode } from "@src/utils/online/setupOnlineMode";

import "./style.less";
import type { PlayPauseProps } from "./types";

export function PlayPause({
  color = "#ff5510",
  fontSize = "8em",
  size = 90,
  shadow = true,
}: PlayPauseProps) {
  const queryClient = useQueryClient();
  const [currentTrackObj, currentTrackId] = usePlayerState(
    useShallow((state) => [
      state.tracks[state.currentTrackId],
      state.currentTrackId,
    ]),
  );
  const status = currentTrackObj?.status || "paused";
  const showPauseWhen = status === "playing";
  const showPlayWhen = status === "paused" || status === "error";

  const onClickPlay = useCallback(() => {
    if (currentTrackId === DEFAULT_TRACK_ID) {
      setupOnlineMode(queryClient);
    }
    player.play();
  }, [currentTrackId, queryClient]);

  useMount(() => {
    const autoplay = window.location.search.includes("autoplay=1");
    if (autoplay) {
      console.log("📻 Autoplaying...");
      onClickPlay();
    }

    // Não podemos adicionar a track aqui porque ao adicioná-la
    // o áudio fica "congelado" no momento em que foi carregado,
    // ficando fora de sincronia com a artwork e demais metadados.
    return () => {
      player.removeTrack(DEFAULT_TRACK_ID);
    };
  });

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.style.setProperty("--size", `${size}px`);
    }
  }, [size]);

  const onPlayPause = useCallback(() => {
    if (showPauseWhen) {
      player.pause();
    } else if (showPlayWhen) {
      onClickPlay();
    }
  }, [showPauseWhen, showPlayWhen, onClickPlay]);
  useListener("playPause", onPlayPause);

  return (
    <IconContext.Provider value={{ color, className: "icon", size: fontSize }}>
      <div className={clsx("PlayPause-component", { shadow })} ref={ref}>
        {showPauseWhen && (
          <div className="button" onClick={() => player.pause()} title="Pause">
            <PiPauseCircleFill className="front" />
            <PiPauseCircleFill className="back" />
          </div>
        )}
        {showPlayWhen && (
          <div className="button" onClickCapture={onClickPlay} title="Play">
            <PiPlayCircleFill className="front" />
            <PiPlayCircleFill className="back" />
          </div>
        )}
        <Oval
          visible={status === "loading"}
          height={size + 10}
          width={size + 10}
          color={color}
          secondaryColor="#000000"
          strokeWidth={4}
        />
      </div>
    </IconContext.Provider>
  );
}
