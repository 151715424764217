import { Tooltip } from "react-tooltip";

import { AudiosOnDemand } from "@src/components/Audios/OnDemand";
import { ScheduledAudios } from "@src/components/Audios/Scheduled";
import { ContactForm } from "@src/components/ContactForm";
import { CurrentTrack } from "@src/components/CurrentTrack";
import { InstallPwa } from "@src/components/InstallPwa";
import { Notification } from "@src/components/Notification";
import { RequestTrack } from "@src/components/RequestTrack";
import { TTS } from "@src/components/TTS";
import { VolumeControl } from "@src/components/VolumeControl";

import { isMobile } from "@src/utils/isMobile";

import "./style.less";
import type { FooterProps } from "./types";

export function Footer({ radio, raspberry = false }: FooterProps) {
  return (
    <footer className="Footer-component">
      <Tooltip
        id="footer"
        disableTooltip={() => isMobile}
        style={{ zIndex: 4 }}
      />
      <CurrentTrack />
      <div className="buttons">
        <InstallPwa />
        {!raspberry && (
          <>
            {radio.tts !== 0 ? <TTS radio={radio} /> : <></>}
            <Notification radio={radio} />
            <ContactForm radio={radio} />
            {radio.ask_song ? <RequestTrack radio={radio} /> : <></>}
            <AudiosOnDemand radio={radio} />
          </>
        )}
        <ScheduledAudios radio={radio} />
        <VolumeControl />
      </div>
    </footer>
  );
}
