import { useEffect, useRef } from "react";

import "./style.less";
import type { BackgroundProps } from "./types";

export function Background({ url, raspberry = false }: BackgroundProps) {
  const blurRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (raspberry) return;
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setTimeout(() => {
        if (blurRef.current) {
          blurRef.current.classList.add("visible");
        }
      }, 100);
    };
  }, [url, raspberry]);

  return (
    <div className="Background-component">
      {!raspberry && (
        <div
          ref={blurRef}
          className="blur"
          style={{ backgroundImage: `url(${url})` }}
        />
      )}
    </div>
  );
}
