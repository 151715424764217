import { useEffect } from "react";

export function useListener(
  eventName: string,
  listener: EventListenerOrEventListenerObject,
): void {
  useEffect(() => {
    document.addEventListener(eventName, listener);
    return () => {
      document.removeEventListener(eventName, listener);
    };
  }, [eventName, listener]);
}
